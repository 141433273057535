/* section one */
#banner-text {
  color: #00bfa5;
  font-family: "Avenir_heavy";
}
/* Banner.css */
.Banner-text {
  width: 100%;
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  line-height: 1.19;
  justify-content: center;
  text-align: center;
  font-family: "Avenir_heavy";
  font-style: normal;
  font-stretch: normal;
  color: #fff;
  opacity: 0.8;
  font-weight: 700;
  font-size: 56px; /* Default font size */
}

.below-banner {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
}

.common-text {
  display: flex;
  align-items: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 22px;
  font-weight: 550;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.common-img {
  width: 50px;
}

/* section two */
.col-main-content,.main-content {
  display: flex;
  flex-direction: row; 
  justify-content: space-between; 
  align-items: center; 
}


.heading-content {
  font-family:"Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.ECO {
  display: flex;
  font-size: 70px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 3;
  letter-spacing: 2px;
}

.PERA_text_form,.PERA_text {
  white-space: 5px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  
}
/* 3rd and 4th section */


.p-text1,.p-text2{
  width: 100%;
  display: flex;

}

.icons-of-home{
  width: 35px;
  height: 35px;

}


p input[name="learn"],p input[name="rewater"]  {
  background-color: #fff;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 16px;
  border-width: thin;
  border-style: solid;
  width: 228px;
  text-align: center;
  border-radius: 6px;
  border-color: black;
  cursor: pointer;
  height: 56px;
  font-weight: 550;
}


p input[name="learn"]:hover,p input[name="rewater"]:hover {
  background-color: #00bfa5;
  text-align: center;
  cursor: pointer;
}



.rewater-content{
  font-size: 80px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 3;
  letter-spacing: 2px;

}

.recycle-line-container,.smart-public-toilet-line-container  {
  width: 25%;
  margin-right:100% ;
  justify-content: right;
  height: 5px;
  color: #00bfa5;
  background-color: #00bfa5;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.elats_right,.elats_left{
  font-family:"Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

}

.p-text{
  margin-left: 17px;
  /* text-align: center; */
  margin-top: 10px;

}

.col-ecolats-left,.col-ecolats-right {
  flex: 1;
  margin-left: 50px;
}

.col-ecolats{
  flex: 1;
  margin-left: 50px;

}

.responsive-image,.demo_img {
  max-width: 100%; /* Set maximum width to prevent overflow */
  height: auto; /* Maintain aspect ratio */
}

.recycle-water-heading-content {

  font-family:"Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
  font-weight: 700;
  
}

/* section five */
.outer-achievement-container{
  width: 100%;
  height: 450px;
  margin-top: 200px;
  justify-content: center;
  text-align: center;
  background-color: #00bfa5 ;
  
}


.owl-carousel-owl-theme{
  display: grid;
  grid-auto-flow: column;
  height: 200px;

}

.item-carousel-card{
  background-color: #fff;
  margin-left: 40px;
  height: 190px;
  border-radius: 8px;
  width: 272px;
 
}


.heading-uppercase{
  font-size: 25px;
  /* font-family: monospace; */
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #fff;
  letter-spacing: 1px;
}

.heading-lowercase{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 17.5px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffffe6;
  padding-bottom: 15px;
}
.item-carousel-car{
  background-color: #3300bf;
  width: 200px;
}
.image-of-mc-icon{
  display: flex;
  width: 130px;
  height: 80px;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
  margin-left: 60px;
 
  
}

.heading-text{
  margin-top: 8px;
  font-size: 20px;
  font-family:  Avenir_heavy;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0,0,0,.87);
  position: relative;
  z-index: 10000;
}

.pera-text-of-mc-icon{
  font-size: 15px;
  margin-top: -13px;
  font-family: Sans-serif;
  white-space: 4px;
  font-weight: 660px;
}


.outer-achivement-container{
  margin-left: 50px;

}
 .achievement-img{
  margin-top: 10px;
  width: 258px;
  height: 170px;
 
 
 } 

.inner-idian-oil-image-container{
 margin-top: 15px;
  width: 90px;
  height: 90px;
}
.p-carousel{
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.h-carousel{
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 620;
}
.text-center-media{
  color: #fff;
  font-size: 23px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
  font-weight: 650;
}
.media_section{
  display: flex;
  padding-left: 20%;
  padding-right: 20%;
  justify-content: space-evenly;
}
.groups_img{
  margin-top: 3%;
}

/* .carousel-wrap{
  scroll-snap-type:x mandatory;
  scroll-padding: 0 24px;
}
.carousel-wrap.heading-text{
  scroll-snap-align: start;
} */


/* section six */
.partner-container{
  width: 100%;
margin-top: 8%;
text-align: center;
}

.partner-heading-content{
width: 100%;
font-size: 22px;
font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
font-weight: 500;
white-space: 2px;
}
.outer-iit-conta{
  width: 100%;
  margin-top: 50px;
}
.outer-iit-logo-container{

display: flex;
width: 100%;
justify-content: center;
text-align: center;
/* justify-content: space-evenly; */
}
.iit-logo-container{
width: 30%;
height: 25%;

}
 .vertical-line-container{
position: relative;
margin-top: 15px;
margin-left: 20px;
margin-right: 20px;
border-right:4px solid #00bfa5;
height: 50px;
border-bottom-right-radius: 4px;
border-top-right-radius:3px;
border-bottom-left-radius:4px ;
border-top-left-radius:3px ;



} 
 .vertical-surrrey-line-container{
  
    position: relative;
    margin-left: 20px;
    margin-top: 25px;
    margin-right: 20px;
    border-right:4px solid #00bfa5;
    height: 55px;
    border-bottom-right-radius: 4px;
    border-top-right-radius:3px;
    border-bottom-left-radius:4px ;
    border-top-left-radius:3px ;
} 
.pt,.pt2,.pt3{
  font-family:"Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 640;
  /* margin-left: 50px; */
}

.surrey-logo-container{
  width: 16%;
  height:12% ;
  margin-top: 20px;
}

.indian-oil-icon{
  width: 6%;
  height: 4%;
  margin-top: 20px;
}


/* Media query for smaller screens */

@media (max-width: 1024px) {
  .Banner-text {
    font-size: 30px; /* Adjust font size for smaller screens */
  }
  .common-img {
    width: 35px;
  }
  .ol-ecolats-left,
  .col-ecolats-right,.belw-ban-inr-con {
    /* flex: none; Reset flex value to prevent stretching */
    font-size: 15;

  }
  .p-text{
    margin-left: 10px;
    font-size: 17px;
  }
  .icons-of-home{
    width: 30px;
    height: 35px;
  }
  .ECO,.rewater-content{
    font-size:45px;
  }

  .recycle-water-heading-content{
    font-size: 30px;
  }
  .pt,.pt2{
    font-size: 16px;
  }
   
}
@media (max-width: 480px) {
  /* section one */
  .Banner-text {
    font-size: 20px; /* Adjust font size for even smaller screens */
  }
  .common-text {
    font-size: 14px; /* Adjust font size for smaller screens */
    font-weight: 400;
    line-height: 1;
  }
  
   .belw-ban-inr-con,.belw-ban-inr-con,.commanText{
    width: 100%;
    text-align: center;
    font-size: 12px;

   }
  .common-img {
    width: 25px;
  }
  /* section three and four */
  .col-ecolats,.col-ecolats-left{
    margin-left:20px;
  }
  .responsive-image,.demo_img{
    margin-left:-10px;

  }
  .elats_right,.elats_left,.PERA_text,.PERA_text_form{
    font-size: 16px;
  }
  
  .ECO,.rewater-content {
    font-size:25px;
  }
  .heading-content,.recycle-water-heading-content{
    font-size:18px ;
  }
  .rewater-content{
    font-size: 30px;

  }
  .p-text{
    margin-left: 8px;
    font-size: 14px;
  }
  .icons-of-home{
    width: 20px;
    height: 25px;
  }
  /* section five */
 .partner-container{
  text-align: center;

  }
  /* section six */
      .pt{
        position: absolute;
        margin-top: 10%;
        font-size: 70%;
        line-height: 1.5;
      }
      .pt2{
      
        position: absolute;
        margin-top: 20%;
        font-size: 70%;
        line-height: 1.5;
      }
      .pt3{
       
        position: absolute;
        margin-top: 20%;
        font-size: 70%;
        line-height: 1.5;
      }

      .vertical-line-container,.vertical-surrrey-line-container{
        display: none;
  
      }
      .iit-logo-container{
        width: 60%;
        height: 34%;
       
        }
      
      .surrey-logo-container{
        width: 30%;
        height:25% ;
        margin-top: 8%;
      }
      .indian-oil-icon{
        width: 12%;
        height: 8%;
        margin-top: 7%;
        text-align: center;
        /* margin-left: 10%; */
  
      }
}

@media (max-width: 320px) {
.p-text{
  margin-left: 6px;
  font-size: 12px;
}
.icons-of-home{
  width: 20px;
  height: 25px;
}
.pt{
  width: 100%;
  margin-top: 10%;
  font-size: 12x;
}
.pt2{
  margin-top: 20%;
  font-size: 11px;
}
.pt3{
  margin-top: 20%;
  font-size: 11px;
}
}

/* section one */
.eco-container{
    width: 100%;
 }
 .img-eco-container{
    width: 100%;
 }
#bannerTextEcolat{
     width: 100%;
     position: absolute;
     top: 75%;
     transform: translateY(-50%);
     line-height: 1.19;
     justify-content: center;
     text-align: center;
     font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
     text-shadow: black 2px 5px 16px;
     color: #fff;
     font-weight: 650;
     font-size: 56px; 
   }
/* section two */
.container-fluid-slide1{
    width: 100%;
}
.image-Con{
    width: 100%;
    display: flex;
}
.heading-content-elat_h{
    padding: 30px 18px;
    text-align: center;
    font-size: 20px;
    font-weight:650 ;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
/* section three */
.challenges_container{
    margin-top: 5%;
    width: 100%;
    text-align: center;
}
.headingOfChallenges{
    font-size: 17px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
}
.keys_con{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.fectorImg{
    width:50px;
    height: 50px;
}
.keyPera{
    width: 100%;
    text-align: center;
}
.key1,.key2,.key3{
    display: flex;
}
.peraKey{
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 640;
}
.LONG-PERA{
    margin-left: 10%;
    margin-right: 10%;
}
 /* section four */
 .mainCon{
    display: flex;
    width: 100%;
    margin-top: 7%;
    justify-content: space-evenly;
}
.solution-Container{
  margin-top:3%;
}
.H_of_smrt_sluton{
    margin-left: 8%;
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 650;
    opacity: 0.9;
}
.pera-solution-Container{
    margin-left: 2%;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}
.eco-vi-Img{
    margin-top: 10%;
    height: 50%;
    width: 85%;
    position: relative;
    opacity: 0.7;
    object-fit: cover;
}
.e_play_btn{
    position: absolute;
    margin-left: 15%;
    margin-top: 20%;
}
/* section five */
.memories-content{
    display: flex;
    justify-content: space-evenly;
    margin-top: 10%;
    width: 100%;
    background-color: #00bfa5;
}
/* section six */
.impect_container{
    margin-top: 8%;
    width: 100%;
}
.impe{
    text-align: center;
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 650;
}
.impe_content{
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;

}


   /* media query */
 @media (max-width: 1024px) {
    /* section one */
    #bannerTextEcolat{
        font-size: 42px;
    }
    /* section two */
    .heading-content-elat_h{
        font-size: 18px;
    }
    /* section three */
    .peraKey{
        font-size: 14px;
        font-weight: 600;
    }
    .fectorImg{
        width:40px;
        height: 40px;
    }

  }
@media (max-width: 768px) {
    /* section one */
    #bannerTextEcolat{
        font-size: 28px;
    }
     /* section two */
     .heading-content-elat_h{
        font-size: 16px;
    }
    /* section three */
    .keys_con{
        flex-direction: column;
        text-align: center;

    }
    .key1,.key2,.key3{
        margin-left:30%;
    }
    .fectorImg{
        width:35px;
        height: 35px;
    }
    .peraKey{
        margin-top: -1px;
        margin-left: 10px;
        font-size: 13px;
    }
    .headingOfChallenges{
        font-size: 14px;
    }
    .LONG-PERA{
        font-size: 14px;
    }
    /* section four */
    .pera-solution-Container{
        font-size: 14px;
    }
    .eco-vi-Img{
        height: 37%;
        width: 27%;
        position: relative;
        opacity: 0.7;
        object-fit: cover;
        margin-left:58%;
        margin-top: -8%;
    }
    .e_play_btn{
        position: absolute;
        margin-left: 64%;
        margin-top: -3%;
    }
    .mainCon{
        display: flex;
        width: 100%;
        flex-direction: column-reverse;
    }
    /* section six */
    .impe_content{
        font-size: 14px;
        line-height: 1.6;
    
    }
}
@media (max-width: 480px) {
    /* section one */
    #bannerTextEcolat{
        font-size: 20px;
    }
     /* section two */
     .heading-content-elat_h{
        font-size: 14px;
    }
    /* section three */
    .key1,.key2,.key3{
        margin-left:30%;
    }
    .peraKey{
        font-size: 11px;
        justify-self: center;
    }
    .fectorImg{
        width:20px;
        height: 25px;
    }
    .headingOfChallenges{
        font-size: 12px;
    }
    .LONG-PERA{
        font-size: 11px;
    }
    /* section four */
    .pera-solution-Container{
        font-size: 13px;
    }
    .solution-Container{
        margin-left: 2%;
    }
    .H_of_smrt_sluton{
        margin-left: 3%;
    }
    .eco-vi-Img{
        height: 37%;
        width: 27%;
        position: relative;
        opacity: 0.7;
        object-fit: cover;
        margin-left:58%;
        margin-top: -7%;
    }
    .e_play_btn{
        position: absolute;
        width: 13%;
        margin-left: 64%;
        margin-top: -2%;
    }
    .mainCon{
        display: flex;
        width: 100%;
        flex-direction: column-reverse;
    }
     /* section six */
     .impe_content{
        font-size: 12px;
        line-height: 1.6;
        margin-left: 3%;
        margin-right: 3%;
    
    }
    .impe{
        font-size: 15px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 650;
    }
   

}
@media (max-width: 320px) {
    /* section one */
    #bannerTextEcolat{
        font-size: 15px;
    }
     /* section two */
     .heading-content-elat_h{
        font-size: 11px;
    }
    /* section three */
    .peraKey{
        font-size: 10px;
        margin-left: 5px;
    }
    .key1,.key2,.key3{
        margin-left:20%;
    }
}


/* Center the carousel container */
.carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40vh;
    /* background: #f4f4f4; */
  }
  
  /* Set overall carousel styles */
  .carousel {
    width: 100%; /* Adjust width as needed */
    max-width: 1200px;
    margin: 0 auto;
    min-height: 30vh;
  }
  
  /* Style each slide */
  .carousel .slide {
    position: relative;
    padding: 10px; /* Add padding */
    margin: 0 10px; /* Space between slides */
    box-sizing: border-box;
    background: #fff; /* Optional: add background */
    border-radius: 8px;
    text-align: center; /* Center text */
  }
  
  /* Styling the images */
  .carousel .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Crop and fit images inside the slide */
    border-radius: 8px;
  }
  
  /* Add text overlay */
  .carousel .slide .overlay-text {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    padding: 10px;
    border-radius: 8px;
    text-align: center;
  }
  
  /* Highlight the active slide */
  .carousel .slide.selected {
    transform: scale(1.1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  /* Styling for carousel control dots */
  .carousel .control-dots {
    margin-top: 20px;
    text-align: center;
  }
  
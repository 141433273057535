/* Default styles */
.container-of-navigation-and-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 35px;
}

.container-of-logo img {
  width: 300px;
}

.container-of-navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-of-nav-text {
  margin: 10px;
  padding: 7px 0; /* Adjusted padding */
  text-decoration: none;
  color: black;
  font-size: 15px;
  font-weight: 500;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  justify-content: flex-end;
  padding-bottom: 6px;
}

.container-of-nav-text::before {
  background-color: #00bfa5;
}

.container-of-nav-text:hover {
  color: #00bfa5;
}

.container-of-nav-text {
  margin-right: 15px;
  text-decoration: none;
  color: black;
}

/* Hamburger styles */
.mobile-menu-toggle {
  display: none; /* Hidden by default for larger screens */
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
}

.bar {
  width: 30px;
  height: 3px;
  background-color: black;
  transition: 0.4s;
}
.but-of-nav{
  margin-left: 80%;
}
.navi-bar{
  margin-left: 80%;
  display: flex;
  flex-direction: column;
  }

/* Media queries */
@media(min-width:0px)and (max-width:899px){
  .container-of-navigation-bar {
    display: none; /* Hide navigation links for smaller screens */
  }
}
@media (min-width:900px)and (max-width:2560px){
.dropDown{
  display: none;
}
}
@media (max-width: 1024px) {
  .container-of-logo img {
    width: 250px;
  }
}

@media (max-width: 768px) {
  .container-of-navigation-and-logo {
    justify-content: center;
    text-align: center;
    flex-direction: column;
    height: auto;
    padding: 5px;
  }

  .container-of-logo img {
    width: 150px;
  }
  .container-of-navigation-and-logo {
    justify-content: center;
    text-align: center;
    flex-direction: column;
    height: auto;
    padding: 5px;
  }

  .container-of-logo img {
    width: 150px;
  }


  .mobile-menu-toggle {
    display: flex; /* Show the hamburger icon */
  }

  .mobile-menu-open .container-of-navigation-bar {
    display: block; /* Show navigation links when mobile menu is open */
  }

  /* Adjust hamburger icon appearance when open */
  .bar.open:nth-child(2) {
    opacity: 0;
  }

  .bar.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .bar.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }

}

@media (max-width: 425px) {
  .container-of-navigation-and-logo {
    justify-content: center;
    text-align: center;
    flex-direction: column;
    height: auto;
    padding: 5px;
  }

  .container-of-logo img {
    width: 100px;
  }
}
/* .section first */
.rewater_banner {
  width: 100%;
}
.img-Content {
  width: 100%;
}
.Text-of-Banner {
  width: 100%;
  position: absolute;
  top: 68%;
  transform: translateY(-35%);
  line-height: 1.19;
  justify-content: center;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-shadow: black 2px 5px 16px;
  color: #fff;
  font-weight: 650;
  font-size: 50px;
}

/* section two */
.recycleContainer {
  width: 100%;
  margin-top: 7%;
}
.pera-of-rcyl {
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 650;
}
.carousel_images {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
/* section three */
.carousel-content {
  width: 100%;
  text-align: center;
}
.carousel-content-pera {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 16px;
  line-height: 1.7;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
/* section four */
.rewater-regenrating-container {
  width: 100%;
  margin-top: 17%;
}
.inner-regenrating-container {
  width: 100%;
  justify-content: space-between;
  margin-top: 4%;
}
.icon-content {
  display: flex;
  margin-left: 10%;
}
.rivar-imag {
  width: 80vh;
  height: 85vh;
  object-fit: cover;
  border-radius: 50%;
}
.play_ic {
  position: absolute;
  margin-left: -22%;
  margin-top: 16%;
}
.rewater_pera {
  margin-left: 3%;
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.rewater_heading {
  margin-left: 6%;
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 650;
  opacity: 0.9;
}
.content_img_con {
  display: flex;
}
/* part two */
.vid-Image,
.rivar_Image {
  width: 100%;
}

/* section five */
.insures-img {
  width: 16%;
  height: 25%;
}
.insures {
  display: flex;
  justify-content: space-between;
}
/* section six */
.h-Of_re {
  text-align: center;
}
.p-Of_re {
  display: flex;
  justify-content: space-evenly;
}
.h-of-insures {
  color: #fff;
  font-size: 23px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 640;
  padding: 3%;
}
.p-Of_re {
  color: #fff;
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 620;
}
/* section seven */
.rejuvenation {
  width: 100%;
  margin-top: 8%;
}
.water-bodies {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 10%;
}
.head-water-bodies {
  text-align: center;
  font-size: 24px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 645;
}
.pera-water-bodies {
  text-align: center;
  padding-left: 8%;
  padding-right: 8%;
  line-height: 1.7;
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.water-bodies1 {
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.rewater-hr {
  width: 15%;
  justify-content: right;
  height: 3px;
  color: #00bfa5;
  background-color: #00bfa5;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
/* section eight /nine/ten*/
.ei-con{
  margin-left: 5%;
}
.Bhowali-town,
.tiliyarLake,
.Radha-kund {
  width: 100%;
 display: flex;
 justify-content: space-around;
 
}
.h-Bhowali-town,
.h-Tiliyar-lake,
.h-radha-kund {
  font-size: 21px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 650;
}
.p-Bhowali-town,
.p-Tiliyar-lake,
.p-radha-kund{
  margin-top: 3%;
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.8;
  width: 100%;
}
.p2-Bhowali-town,
.p2-Tiliyar-lake,
.p2-radha-kund {
  margin-top: 3%;
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.8;
  width: 100%;
}
.bhowaliImg,
.radhakundImg {
  width: 300px;
}
.tiliyarImage{
  width: 73%;
}

/* media query */
@media (min-width: 0px) and (max-width: 1024px) {
  .vidImage {
    display: none;
  }
}
@media (min-width: 786px) and (max-width: 2560px) {
  .rewater-hr {
    display: none;
  }
}
@media (min-width: 1025px) and (max-width: 2560px) {
  .vid-Image {
    display: none;
  }
}

@media (max-width: 1024px) {
  /* section one */
  .Text-of-Banner {
    font-size: 35px;
  }
  /* section two */
  .carousel-content-pera {
    font-size: 16px;
  }
  /* section four */
  .content_img_con {
    flex-direction: column;
  }
  .play_ic {
    position: absolute;
    margin-left: -59%;
    margin-top: 16%;
  }

  .Bhowali-town,
  .tiliyarLake,
  .Radha-kund {
    width: 100%;
    display: grid;
    grid-template-columns: 5fr 5fr;
  }
  .ei-con{
    margin-left: 5%;
  }
}
@media (max-width: 786px) {
  /* section one */
  .Text-of-Banner {
    font-size: 24px;
  }
  /* section two */
  .carousel-content-pera {
    font-size: 14px;
  }
  .pera-of-rcyl {
    font-size: 17px;
  }

  /* section seven */
  .water-bodies {
    flex-direction: column;
    text-align: center;
  }
  /* section eight /nine/ten*/
  .ei-con{
    margin-left: 5%;
  }
  .bhowaliImg,
 .radhakundImg {
  position: absolute;
    width: 28%;
    margin-left: 57%;
    margin-top: 2%;
}

.p-Bhowali-town,
.p-radha-kund{
  width:100%;
}
.p2-Bhowali-town,
.p2-radha-kund{
  width:180%;
}

.p2-Tiliyar-lake{
  width: 231%;
  margin-left: -88%;
}
.p-Tiliyar-lake{
  width:137%;
}
.tiliyarLake{
  width: 78%;

} 
}
@media (max-width: 480px) {
  /* section one */
  .Text-of-Banner {
    font-size: 16px;
  }
  /* section two */
  .pera-of-rcyl {
    font-size: 14px;
  }
  /* section three */
  .carousel-content-pera {
    font-size: 12px;
  }
  /* section four */
  .icon-content {
    margin-left: 3%;
  }
  /* section seven */
  .head-water-bodies {
    font-size: 18px;
  }
  /* section eight and nine */
  .h-Bhowali-town,
  .h-Tiliyar-lake,
  .h-radha-kund {
    font-size: 14px;
  }
  .p-Bhowali-town,
  .p-Tiliyar-lake,
  .p-radha-kund,
  .p2-Bhowali-town,
  .p2-Tiliyar-lake,
  .p2-radha-kund {
    font-size: 12px;
  }
  .tiliyarImage,.bhowaliImg,
  .radhakundImg{
    margin-top: 10%;
  }
}
@media (max-width: 320px) {
  /* section one */
  .Text-of-Banner {
    font-size: 13px;
  }
  /* section two */
  .pera-of-rcyl {
    font-size: 12px;
  }
}
